<template>
  <base-page>
    <el-card style="margin: 5px 0px 5px 0px">
      <el-collapse accordion style="border: none">
        <el-collapse-item name="1" style="border: none">
          <template slot="title">
            <div slot="header" class="clearfix">
              <titlu-pagina Titlu="Niruri" @on_add_clicked="show_dialog()" :AdaugaVisible="true" />
            </div>
          </template>
          <div class="filtre">
            <el-form @submit.prevent.native="refresh_info()" label-position="top">
              <el-row :gutter="20">
                <el-col :md="4">
                  <el-form-item label="Data emitere" size="mini" >
                    <el-date-picker size="mini" v-model="Filters.Data" type="date" value-format="yyyy-MM-dd" format="dd.MM.yyyy" />
                  </el-form-item>
                </el-col>
                <el-col :md="4">
                  <el-form-item label="Nume furnizor" size="mini" >
                    <el-input size="mini" v-model="Filters.NumeFurnizor" />
                  </el-form-item>
                </el-col>
                <el-col :md="4">
                  <el-form-item label="Numar" size="mini" >
                    <el-input size="mini" v-model="Filters.Serie_Numar" />
                  </el-form-item>
                </el-col>
                <el-col :md="4">
                  <el-form-item label="CIF furnizor" size="mini" >
                    <el-input size="mini" v-model="Filters.CifFurnizor" />
                  </el-form-item>
                </el-col>
                <el-col :md="4">
                  <el-form-item label="Reg. Com. furnizor" size="mini" >
                    <el-input size="mini" v-model="Filters.RegComFurnizor" />
                  </el-form-item>
                </el-col>
                <!-- <el-col >
                            <el-form-item label='Status plata' >
                                <el-select class='full-width' v-model='Filters.StatusPlata' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option label='platita' value='platita'></el-option>
                                    <el-option label='neplatita' value='neplatita'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col >
                            <el-form-item label='Status' >
                                <el-select class='full-width' v-model='Filters.Status' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option label='draft' value='draft'></el-option>
                                    <el-option label='emisa' value='emisa'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col >
                            <el-form-item label='Anulata' >
                                <el-checkbox class='full-width' v-model='Filters.EsteAnulata' true-label='1' false-label='0'> DA </el-checkbox>
                            </el-form-item>
                                    </el-col>                         -->
                <el-col style="width: 100%">
                  <div class="footer-form">
                    <el-button size="mini" type="primary" native-type="submit" @click="refresh_info()">
                      Aplica
                    </el-button>
                    <el-button size="mini" type="danger" native-type="submit" @click="reset()">
                      Reseteaza
                    </el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>

    <el-table :header-cell-style="{ background: '#393E46' }" :data="Results" class="tabelRandMic">
      <el-table-column prop="Id" label="#" width="44" />
      <el-table-column prop="NumeFurnizor" label="Furnizor" width="200" />
      <el-table-column prop="NumeGestiune" label="Gestiune" />
      <!-- <el-table-column prop="CifFurnizor" label="CIF" /> -->
      <!-- <el-table-column prop="RegComFurnizor" label="Reg. Com." /> -->
      <el-table-column prop="Numar" label="Numar" />
      <el-table-column prop="NumarComanda" label="Comanda" />
      <el-table-column label="Data document">
        <template slot-scope="scope">
          {{ scope.row.DataDocument | Data }}
        </template>
      </el-table-column>
      <el-table-column align="right" prop="ValoareFaraTVA" label="Valoare fara TVA EUR">
        <template slot-scope="scope">
          {{ (scope.row.ValoareFaraTVA) | format_money }}
        </template>
      </el-table-column>

      <el-table-column align="right"  label="Valoare fara TVA RON">
        <template slot-scope="scope">
          {{ (scope.row.ValoareFaraTVA * scope.row.Curs) | format_money }}
        </template>
      </el-table-column>
      <el-table-column  label="Actiuni" align="right" min-width="110px">
        <template slot-scope="scope">
          <el-tooltip content="Modificare">
            <!-- <el-tooltip v-if="$has_right('modificare-factura')" content="Modificare"> -->
            <el-button :disabled="scope.row.LunaBlocata" type="primary" size="mini" icon="el-icon-edit" circle @click="show_dialog(scope.row.Id)" class="butonReg"/>
          </el-tooltip>
          <el-tooltip content="Imprimare">
            <el-button type="info" size="mini" icon="el-icon-printer" circle @click="print(scope.row.Id)" class="butonReg"/>
          </el-tooltip>
          <el-tooltip content="Descarca PDF">
            <el-button type="success" size="mini" icon="el-icon-download" circle @click="downloadPdf(scope.row)"  class="butonReg" />
          </el-tooltip>
    
          <el-tooltip content="Sterge" >
              <el-button :disabled="scope.row.LunaBlocata" size="mini" type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" class="butonReg"/>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="refresh_info" @current-change="refresh_info" :page-size.sync="PaginationInfo.PerPage"
      :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
    <Niruri-dialog ref="dlg" @save="refresh_info()" />
    <div>
      <VueHtml2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="false"
          :paginate-elements-by-height="1500"
          filename="Nir"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a2"
          pdf-orientation="portrait"
          pdf-content-width="1600px"
          ref="html2Pdf"
        >
          <section slot="pdf-content">
            <div v-html="fetchedHTML"></div>
          </section>
        </VueHtml2pdf>
    </div>
  </base-page>
  

</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Niruri_dialog from "@/pages/niruri/Niruri_dialog.vue";
import TitluPagina from "@/widgets/TitluPagina";
import { jsPDF } from "jspdf";
import VueHtml2pdf from 'vue-html2pdf'
import html2pdf from "html2pdf.js";


export default {
  name: "niruri",
  extends: BasePage,
  components: {
    "base-page": BasePage,
    "Niruri-dialog": Niruri_dialog,
    "titlu-pagina": TitluPagina,
    VueHtml2pdf
  },
  data() {
    return {
      Results: [],
      base_url: "",
      fetchedHTML:'',
      Info: {
        masini: [],
      },
      Filters: {
        Data: "",
        NumeFurnizor: "",
        CifFurnizor: "",
        RegComFurnizor: "",
        Serie_Numar: "",
      },
      OrderBy: {},
      PaginationInfo: {
        Page: 1,
        PerPage: 50,
        RowCount: 0,
        PageSizes: [10, 25, 50, 100, 200],
      },
    };
  },
  methods: {
    async get_info() {
      // if (!this.$has_right('vizualizare-facturi')){
      //       this.$router.push('/no-rights')
      //       return
      // }
      var response = await this.post("niruri/get_info");
      this.Info.masini = response.masini;
      //if( this.Filters.IdMasina.length > 0 ) this.Filters.IdMasina = this.Info.masini[0].Id;
      this.refresh_info();
    },

    async refresh_info() {
      var response = await this.post("niruri/index", {
        Filters: this.Filters,
        OrderBy: this.OrderBy,
        PaginationInfo: this.PaginationInfo,
      });
      this.Results = response.Results;
      this.PaginationInfo = response.PaginationInfo;
      //
      this.select_menu_item("niruri");
    },
    reset() {
      this.Filters = {
        Data: "",
        NumeFurnizor: "",
        CifFurnizor: "",
        RegComFurnizor: "",
        Serie_Numar: "",
      };
      this.refresh_info();
    },

    async downloadPdf(data){
      let res = await this.post('niruri/print_niruri', { IdNir: data.Id });
      
      // this.fetchedHTML = res.html;
      const blankWindow = window.open('', '_blank');

      html2pdf(res.html, {
				margin: 0,
  			filename: 'Nir',
        html2canvas: { scale: 4 },
        orientation: 'landscape',
			}).then(() => {
        blankWindow.close();
      });

      
      // await this.$refs.html2Pdf.generatePdf();
      // blankWindow.close();

      // const toDownloadDocument = new jsPDF()
      // const blankWindow = window.open('', '_blank');

      // toDownloadDocument.html(res.html, {
      //   callback: () => {
      //     toDownloadDocument.save('Nir_' + data.Numar)
      //     blankWindow.close();
      //   },
      //   x: 15,
      //   y: 15,
      //   width: 180,
      //   windowWidth: 1200,
      // })
    },

    async delete_item(item) {
      this.$confirm(`Sunteti sigur ?`, "Warning", {
        type: "warning",
      })
        .then(async () => {
          await this.post("niruri/delete_item", { id: item.Id });
          this.refresh_info();
          this.$message({
            type: "success",
            message: "Stergere efectuata cu succes",
          });
        })
        .catch(() => {
          this.$message({ type: "info", message: "Stergere anulata" });
        });
    },

    show_dialog(id) {
      this.$refs.dlg.show_me(id);
    },

    async print(id) {
      // let res = await this.post('niruri/pdf_nir', { IdNir: id });
      // console.log(res);
      // const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', 'nir.pdf');
      // document.body.appendChild(link);
      // link.click();
      
      // return;
      let res = await this.post('niruri/print_niruri', { IdNir: id });

      let doc = window.open("", "_blank");
      doc.document.write(res.html);
    },
  },
  mounted() {
    this.base_url = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.top50 {
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  .filtre {
    display: flex;
    grid-template-columns: none;
  }

  .el-table tr {
    word-break: break-word;
    // font-size: 12px !important;
  }

  .footer-form {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}

.filtre {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(2);

  .el-col {
    width: 31%;
    margin-right: 2%;
  }

  .el-date-editor {
    width: 100%;
  }

  // grid-auto-rows: 100px;
  .el-input-number {
    width: 100% !important;
  }
}
</style>
